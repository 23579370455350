import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import doArtLogo from "../img/doartlogo.png";

export const TopBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="transparent"
        style={{
          backdropFilter: "blur(7px)",
          WebkitBackdropFilter: "blur(7px)",
        }}>
        <Toolbar>
          <img
            src={doArtLogo}
            alt={"do-art"}
            loading="lazy"
            width={"80rem"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
          <div style={{ flexGrow: 1 }}></div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

import { Grid, Typography, ImageList, ImageListItem } from "@mui/material";
import Faith47Photo from '../img/Faith 47_"The Preciousness of the Hunt"_South Park.jpeg';
import fingersPhoto from "../img/fingers.webp";
import birdsMural from "../img/birdsmural.webp";
import pairposingmural from "../img/pairposingbwmural.webp";
import indoorAction from "../img/indoorinaction.webp";
import fourhand from "../img/fourhand.webp";
import roadtongueheads from "../img/tongueheads.webp";
import womanhandbirdmural from "../img/womanhandbirdmural.webp";
import buildingfacade from "../img/buildingfacade.webp";
import manbluepainting from "../img/manbluepainting.webp";
import chickenmural from "../img/chickenmural.webp";
import React from "react";

export const AboutUs = () => {
  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid item>
        <HeroImages />
      </Grid>
      <Grid item>
        <AboutUsOverview />
      </Grid>
      <Grid item>
        <MissionVision />
      </Grid>
      <Grid item>
        <GetInvolved />
      </Grid>
      <Grid item>
        <BoardOfDirectors />
      </Grid>
    </Grid>
  );
};

const HeroImages = () => {
  const itemData = [
    { img: Faith47Photo, title: "img1" },
    { img: fingersPhoto, title: "img2" },
    { img: birdsMural, title: "img3" },
    { img: indoorAction, title: "img4" },
    { img: pairposingmural, title: "img5" },
    { img: fourhand, title: "img6" },
    { img: roadtongueheads, title: "img7" },
    { img: womanhandbirdmural, title: "img8" },
    { img: buildingfacade, title: "img9" },
    { img: manbluepainting, title: "img10" },
    { img: chickenmural, title: "img11" },
  ];
  return (
    <ImageList variant="masonry" cols={5}>
      {itemData.map((item, index) => (
        <ImageListItem key={`hero-images-${index}-home-page`}>
          <img src={item.img} alt={item.title} loading="lazy" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const AboutUsOverview = () => {
  return (
    <Grid container direction={"row"} justifyContent={"center"}>
      <Grid item lg={6}>
        <Typography>
          The Do Art Foundation is a non­profit organization that develops
          multi-disciplinary artistic projects and programs with–and for–the
          public to bring creative insight and inspiration to our contemporary
          culture. Working closely with community members, civic leaders, and
          artists, Do Art produces custom-conceived projects that are designed
          with each specific location in mind, tailoring each project to the
          specific needs and wants of the community it is serving while taking
          into account its anticipated audience and pre-existing surroundings.
          As a liaison and incubator, Do Art not only bridges the gap between
          creatives, developers, and funders, but also fosters fledgling and
          emerging artists, granting them exposure in the public realm while
          connecting them with municipal resources. Aspiring to propel art in
          the public landscape, support community-inspired creativity, and
          advance our cultural legacy as a collective global community, Do Art
          works to ensure it impacts the communities it serves in a positive
          way, embracing art as a tool of empowerment and engagement, progress
          and transformation, community cohesion and cultural evolution.
        </Typography>
      </Grid>
    </Grid>
  );
};

const GetInvolved = () => {
  const sections = [
    {
      name: "Photography",
      desc: "We are looking for skilled up and coming photographers to cover our events and projects and document artists that work with Do Art creating public art in Los Angeles, Montreal or any city around the world! You will need to have your own equipment and ability to provide high res images, or images ready for publication on social media and upload them to our server – it’s a great way to get exposure and experience, as well as develop a portfolio in the art world.",
    },
    {
      name: "Writing for Social media",
      desc: "We are looking for volunteers with an interest in Social Media Marketing to research like-minded online publications that will cover artists working in public art and art-related events that we can repost on our social media sites. This position can also cover creating unique content for our events, shows lead by Do Art or based on artists that we work with.",
    },
    {
      name: "Research",
      desc: "For the person who wants practical skills in arts development in the local community we are looking for help on researching opportunities for artists and public art in LA and Montreal. This would include grants, community events, foundations to support and upcoming building development projects.",
    },
    {
      name: "Participate in projects",
      desc: "Our strength and foundation is reliant upon responsible volunteers who care deeply about fostering culture and helping the artists create their vision for us all to share. This volunteer position would be for those who want to have hands-on experience in assisting the Do Art staff and artists working on upcoming events, openings, and projects that we have in LA and Montreal.",
    },
  ];

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      spacing={2}>
      <Grid item>
        <Typography variant="h3">Get Involved</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        alignItems={"top"}
        justifyContent={"center"}>
        {sections.map((section, index) => (
          <Grid
            item
            key={`getinvolved-section-${index}-${section.name}`}
            md={3}>
            <Grid item>
              <Typography variant="h5">{section.name}</Typography>
            </Grid>
            <Grid item>
              <Typography>{section.desc}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const BoardOfDirectors = () => {
  const directors = [
    {
      name: "Josh Flemming",
      title: "President",
      desc: "An accomplished creative and technical director, Josh Flemming has worked extensively as an architectural designer in the entertainment, design, and trade-show industries. Not only has his work been featured in various design magazines (Biz Bash, Event Design), but he has also won awards (Best Outdoor Consumer Event Exhibit and Best Environment by Event Design Magazine) for the dozens of installations he has designed. In addition to co-founding the creative event company, The Do LaB, Josh is also co-founder of the Lightning in a Bottle festival, southern California’s premier eco music and arts festival.",
    },
    {
      name: "Jesse Flemming",
      title: "Vice President",
      desc: "Jesse Flemming has worked in the entertainment industry for the past decade and, after a series of successes in music recording, performance, and production, he co-founded the event company, The Do LaB, where he focuses on the creative decision-making and musical direction of the business. Jesse’s enthusiasm for—and interest in—DO ART is rooted in his desire to have a direct hand in supporting the next generation of artists by fostering children’s programs and helping young people follow their creativity and realize dreams.",
    },
    {
      name: "Dede Flemming",
      title: "Treasurer",
      desc: "Driven by a fascination for sociological analysis, the study of the production process, and the critique of artistic styles and aesthetic forms, Dede Flemming pursue these three interrelated areas of media studies in both his professional and personal life. Thanks to his formal education at Penn State University and his work in television production, Dede’s business savvy and practical skills have granted him a crucial understanding of—and unique insight into—both the financial and operative necessities of a large company. Dede’s passion for facilitating creativity and actualizing the artistic visions of his partners has played an integral role both in the success of The Do LaB and in the growth of the accomplished art festival he also co-founded, Lightning in a Bottle. After his global travels affirmed the importance of integrating art into communities far and wide, Dede decided to act upon this revelation and, through DO ART, is committed to using his skills to encourage community creativity and introduce more art into the public realm.",
    },
  ];

  return (
    <Grid
      item
      container
      alignItems={"center"}
      justifyContent={"center"}
      spacing={2}>
      <Grid item>
        <Typography variant="h3">Board</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        justifyContent={"center"}
        alignItems={"top"}>
        {directors.map((director, index) => (
          <Grid
            item
            lg={4}
            key={`${index}-DirectorCard`}
            container
            direction={"row"}
            justifyContent={"center"}
            spacing={2}>
            <Grid item>
              <Typography variant="h6">
                {director.name} - {director.title}
              </Typography>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {director.desc}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const MissionVision = () => {
  const sections = [
    {
      name: "Mission",
      desc: "Do Art increases the value and vitality of communities by improving cultural access to, and visibility of, art. Focused on the creation, and curation of art within the public realm, we are a dynamic liaison between artists, urban developers, community members, local businesses, & civic leaders, to place artwork that achieves shared goals. Recognizing art’s undeniable power as a source of inspiration and invigoration, Do Art designs and develops projects that revitalize both physical and social landscapes. We create artistic experiences that catalyze cultural exchanges, connect communities, and engage the public’s imagination. Do Art serves the public, first and foremost, and aspires to improve and augment the overall quality of life of those individuals and neighborhoods it services through culturally-attuned and aesthetically-stimulating artistic manifestations.",
    },
    {
      name: "Vision",
      desc: `Do Art Foundation is a non-profit organization that answers the need of art access and education for our society. Do Art aims to bring art in the public realm so that all people and communities may create dialogue and participate in our cultural development. We believe that art should be made visible in our lives as a practice of our human experience. As an international organization, our mandate is to engage art as a forum to foster exchange of ideas and culture. Do Art will focus on projects and programs that involve unconventional art education and expression. In our vision, artists will unite to foster creativity, communication, and understanding through direct access to all artistic mediums in the public space.`,
    },
  ];

  return (
    <Grid
      item
      container
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
      spacing={2}>
      <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
        <Typography variant="h3">Mission + Vision</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        alignItems={"top"}
        justifyContent={"center"}>
        {sections.map((section, index) => (
          <Grid
            item
            container
            direction={"column"}
            xl={5}
            alignItems={"top"}
            key={`mission-vision-${index}`}>
            <Grid item>
              <Typography variant="h5">{section.name}</Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {section.desc}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

export const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ background: "black", alignItems: "center" }}>
        <Toolbar>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={2} container>
              <Typography>Contact:</Typography>
            </Grid>
            <a href="mailto:info@thedoart.org">
            <Grid item xs container direction={"row"} alignItems={"center"}>
              <Grid item>
                  <IconButton>
                    <MailIcon sx={{ color: "white" }} />
                  </IconButton>
              </Grid>
              <Grid item>
                <Typography>Infomation: info@thedoart.org</Typography>
              </Grid>
            </Grid>
            </a>
            <a href="mailto:volunteers@thedoart.org">
            <Grid item xs container direction={"row"} alignItems={"center"}>
              <Grid item>
                  <IconButton>
                    <MailIcon sx={{ color: "white" }} />
                  </IconButton>
              </Grid>
              <Grid item>
                <Typography>Volunteers: volunteers@thedoart.org</Typography>
              </Grid>
            </Grid>
            </a>
            <Grid
              item
              xs={12}
              md={4}
              container
              direction={"row"}
              alignItems={"center"}>
              <Grid item>
                <IconButton
                  onClick={() => {
                    const newWindow = window.open(
                      "https://goo.gl/maps/ArWGHbYbrTRaJG8Z9"
                    );
                    newWindow.opener = null;
                  }}>
                  <LocationOnIcon sx={{ color: "white" }} />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Typography>
                  1024 Santee St. Ste. 600 Los Angeles, CA 90015
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar sx={{ display: { xs: "block", sm: "none" } }} />
      </AppBar>
    </Box>
  );
};

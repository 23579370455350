import { Toolbar, createTheme, ThemeProvider, Grid } from "@mui/material";
import { AboutUs } from "./components/AboutUs";
import { TopBar } from "./components/TopBar";
import { Footer } from "./components/Footer";

const theme = createTheme({
  palette: { mode: "light", primary: { main: "#00a1ae" } },
  typography: {
    fontFamily: "Abel",
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          sx={{
            background: theme.palette.mode === "dark" ? "black" : "white",
          }}
          justifyContent={"center"}>
          <Grid item>
            <TopBar />
            <Toolbar />
            <Grid container item sx={{ maxWidth: "80vw" }}>
              <AboutUs />
            </Grid>
            <Toolbar />
          </Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
